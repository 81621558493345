.login-container {
  width: 100%;
  margin: 0 auto;
}
.signin-container {
  width: 100%;
  margin: 0 auto;
}
.form-container {
  width: 420px;
  margin: 0 auto;
}
.img-container {
  height: 100vh;
  object-fit: cover;
}
.home-container {
  width: 100%;
  margin: 0 auto;
}
.edit-container {
  width: 100%;
  margin: 0 auto;
}
a {
  text-decoration: none;
}
a:focus {
  outline: none;
}
.icon-color {
  color: #ecb22e;
}
.filter-icon {
  color: white;
}
.card-color {
  color: white;
}
.edit-container.blurred {
  backdrop-filter: blur(8px);
}
.container {
  width: 1280px;
  height: 100%;
  margin: 0 auto;
}
.container-create {
  width: 596px;
  height: 100%;
  margin: 0 auto;
}
.container-edit {
  width: 596px;
  height: 100%;
  margin: 0 auto;
  margin-top: 35px;
}
.star-rated {
  color: #ecb22e;
}
.filter-options-scroll {
  max-height: 120px;
  overflow-y: auto;
}
.slick-track {
  display: flex;
}

.fixed-category {
  position: relative;
}

.container-search {
  width: 950px;
  height: 100%;
  margin: auto;
}

@media (min-width: 300px) {
  .container-search {
    width: 100%;
  }
}

.overflow-wrap-break-word {
  overflow-wrap: break-word;
  white-space: pre-wrap;
}

.container-search-inputs {
  width: 95%;
  height: 100%;
}

.plus-icon-label {
  width: 100px;
  height: 100px;
  position: relative;
  overflow: hidden;
}
.reservation-container {
  height: 650px;
  overflow: auto;
}

.plus-icon-label img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image-container {
  height: 300px;
  overflow: auto;
}
.fc .fc-daygrid-day-bottom {
  font-size: 0;
}

.fc-daygrid-event-harness ::after {
  display: none;
}

.whitespace-pre-line {
  white-space: pre-line;
}

.whitespace-pre-wrap {
  white-space: pre-wrap;
}
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}
.popup-content {
  max-width: 80%;
  overflow-y: auto;
}
.popup-container {
  height: 210px;
  overflow: auto;
}

.carousel {
  margin-top: 80px;
}

.carousel-container {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
}

.media {
  width: 100%;
  height: 310px;
  object-fit: fill;

  margin-right: 15px;
}

.media video {
  width: 100%;
  height: 100%;
  border-radius: 8px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  max-width: 90%;
  max-height: 90%;
  overflow: hidden;
  background: #fff;
  position: relative;
  padding: 10px;
}

.modal-content img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}

.modal-content video {
  width: 100%;
  height: 100%;
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
}

.full-width-height {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.custom-quill-editor .ql-editor {
  min-height: 100px;
}

.custom-quill-editor .ql-editor {
  min-height: 100px;
}
